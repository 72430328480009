<!--
 * @Description: 直播排期-筛选区域
 * @Autor: 胡椒
 * @Date: 2020-08-04 14:53:36
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-08 17:09:06
-->
<template>
  <a-form
    class="filter-condition-form"
    layout="inline"
  >
    <a-form-item>
      <a-select
        v-model='searchParams.order_status'
        style="width: 120px;"
        placeholder="订单状态"
        allowClear
      >
        <a-select-option
          v-for="item in expressStatusOptions"
          :key="item.id"
        >{{ item.label }}</a-select-option
        >
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-input style="width: 200px"
        v-model="searchParams.order_id"
        placeholder="订单ID"
      />
    </a-form-item>
    <a-form-item>
      <a-input style="width: 200px"
        v-model="searchParams.product_id"
        placeholder="商品ID"
      />
    </a-form-item>

    <a-form-item>
      <a-select
        style="width: 120px"
        placeholder="时间方式"
        :default-active-first-option="false"
        v-model='timeselect'
        @change="setTimeselect"
      >
        <a-select-option v-for="item in TimeList"  :key="item.id">{{ item.username }}</a-select-option>

      </a-select>
      <a-range-picker
         :value=[timeone,timetwo]
        :showTime="{
        hideDisabledOptions:true,
        defaultValue: ['YYYY-MM-DD HH:mm:ss','YYYY-MM-DD HH:mm:ss']
      }" format="YYYY-MM-DD HH:mm:ss" style="margin-left: 10px" @change="onChangeDate" />
    </a-form-item>
    <!--        v-model='searchParams.search_user_type'-->
    <a-form-item>
      <a-select
        style="width: 120px"
        placeholder="人员方式"
        :default-active-first-option="false"
        v-model="searchParams.search_user_type"
        @change="setusertype"
      >
        <a-select-option v-for="item in PeopleList"  :key="item.id">{{ item.username }}</a-select-option>
      </a-select>
      <a-input style="width: 150px;margin-left: 10px"
               v-model="searchParams.search_user_value"
               placeholder=""
      />
    </a-form-item>
    <a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        @click="handleSubmit"
        >查询</a-button
      >
      <a-button
      type="primary"
      style="margin-left: 10px"
      html-type="submit"
      @click="refreshSearchParams"
    >重置</a-button
    >
      <a-button v-if="iscanExport"
        type="primary"
        style="margin-left: 10px"
        html-type="submit"
        :loading="exportlistLoading"
        @click="exportAllianceOrderClick"
      >导出</a-button
      >
      <a-button v-else disabled
                style="margin-left: 10px"
                html-type="submit"
                :loading="exportlistLoading"
      >导出</a-button
      >
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  name: 'ScheduleSearchBar',
  props: {
    // 加载中
    loading: {
      type: Boolean,
      default: false,
    },
    exportLoading: {
      type: Boolean,
      default: false,
    },
    searchParams: {
      type: Object,
      default: function() {
        return {
          order_status: undefined, // 订单状态
          order_id: undefined,
          product_id:undefined,
          search_user_type: 1,
          search_user_value: undefined,
          pay_success_time_start: undefined, // 开始时间, 配合time_type
          pay_success_time_end: undefined, // 开始时间, 配合time_type
          settle_time_start: undefined, // 开始时间, 配合time_type
          settle_time_end: undefined, // 开始时间, 配合time_type
        };
      }
    },

  },

  data() {
    return {
      iscanExport: false,
      timeone: undefined,
      timetwo: undefined,
      timeselect: 1,
      expressStatusOptions: [{
        label:'已支付',
        id: 1
      },{
        label:'已退款',
        id: 2
      },{
        label:'已结算',
        id: 3
      }],
      TimeList: [{
        username:'付款时间',
        id: 1
      },{
        username:'结算时间',
        id: 2
      }],
      PeopleList:[{
        username:'渠道人员',
        id: 1
      },{
        username:'招商人员',
        id: 2
      },{
        username:'达人昵称',
        id: 3
      }],
      unwatch: null,
      form: this.$form.createForm(this),
      listLoading: this.loading,
      exportlistLoading: this.exportLoading,
    };
  },

  created() {
    if (this.$store.state.user.rights.includes("alliance_orders_manage_export")) {
    this.iscanExport=true;
  }
    this.init();
  },

  destroyed() {
    if (this.unwatch) {
      this.unwatch();
    }
  },

  methods: {
    setTimeselect(value){
      this.timeselect = value? Number(value):undefined;
      if(this.timeselect===1){
        this.searchParams.pay_success_time_start = this.timeone;
        this.searchParams.pay_success_time_end = this.timetwo;
        this.searchParams.settle_time_start = undefined;
        this.searchParams.settle_time_end = undefined;
      }else{
        this.searchParams.settle_time_start = this.timeone;
        this.searchParams.settle_time_end = this.timetwo;
        this.searchParams.pay_success_time_start = undefined;
        this.searchParams.pay_success_time_end = undefined;
      }
    },
    setusertype(value){
      this.searchParams.search_user_type = value? Number(value):undefined;
    },
    init() {
      this.unwatch = this.$watch('loading', (val) => {
        this.listLoading = val;
      });
      this.unwatch = this.$watch('exportLoading', (val) => {
        this.exportlistLoading = val;
      });
    },
    //重置，刷新
    refreshSearchParams(){
      this.timeselect=1;
      this.timeone=undefined;
      this.timetwo=undefined,
      this.searchParams={
        order_status: undefined, // 订单状态
        order_id: undefined,
        product_id:undefined,
        search_user_type: 1,
        search_user_value: undefined,
        pay_success_time_start: undefined, // 开始时间, 配合time_type
        pay_success_time_end: undefined, // 开始时间, 配合time_type
        settle_time_start: undefined, // 开始时间, 配合time_type
        settle_time_end: undefined, // 开始时间, 配合time_type
      };
      this.$emit('submit',this.searchParams);
    },

    // 检索按下
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.$emit('submit',this.searchParams);
        }
      });
    },
    exportAllianceOrderClick(e){
      e.preventDefault();
      this.$emit('exportAllianceOrderClick');
    },

    setField(value) {
      this.currentField = value;
    },

    // 时间段选择
    onChangeDate(date, dateString) {
      this.timeone=dateString[0];
      this.timetwo=dateString[1];
      if(this.timeselect===1){
        this.searchParams.pay_success_time_start = dateString[0];
        this.searchParams.pay_success_time_end = dateString[1];
        this.searchParams.settle_time_start = undefined;
        this.searchParams.settle_time_end = undefined;
      }else{
        this.searchParams.settle_time_start = dateString[0];
        this.searchParams.settle_time_end = dateString[1];
        this.searchParams.pay_success_time_start = undefined;
        this.searchParams.pay_success_time_end = undefined;
      }

    },

    // select输入过滤
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

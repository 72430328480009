/*
 * @Author: 前胡
 * @LastEditors: 肖槿
 * @Date: 2020-03-06 15:15:21
 * @LastEditTime: 2021-10-26 17:30:22
 * @Description: 商品管理相关api
 */
import axios from './axios'




// 校验折一商品是否存在
export const getCheckKrGoods = params => {
  return axios.get(`/api/v1/check-kuran-goods/`, {
    params
  })
}

// 获取折一商品
export const getKrGoodsList = params => {
  return axios.get(`/api/operators/v1/kuran-goods/`, {
    params
  })
}

// 导出折一商品
export const exportKrGoodsList = params => {
    return axios.get(`/api/operators/v1/export-kuran-goods/`, {
        params
    })
}

// 新增折一商品
export const postKrGoods = data => {
  return axios.post(`/api/operators/v1/kuran-goods/`, data)
}

// 更新折一商品
export const patchKrGoods = data => {
  return axios.patch(`/api/operators/v1/kuran-goods/`, data)
}

// 批量上下架商品
export const patchGoodsStatus = data => {
  return axios.patch(`/api/operators/v1/batch-update-kuran-goods-sale-status/`, data)
}

// 批量上传商品
export const importKrGoods = data => {
  return axios.patch(`/api/v1/upload-file/`, data)
}

// 获取淘宝商品
export const getTaobaoGoodsList = params => {
  return axios.get(`/api/operators/v1/taobao-goods/`, {
    params
  })
}

// 获取优惠券信息
export const getCouponDetail = data => {
  return axios.post(`/api/operators/v1/coupon-detail/`, data)
}

// 定时上下架商品
export const patchTimingOnSale = (id, data) => {
  return axios.patch(`/api/operators/v1/timing-on-sale/${id}/`, data)
}

// 获取批量导入结果列表
export const getImportResultList = params => {
  return axios.get('/api/operators/v1/upload-logs/', { params })
}

// 获取商品简略信息
export const getGoodsBrief = params => {
  return axios.get('/api/v1/goods-brief/', { params })
}

// 自定义分类接口
export const getGoodsCategorys = params => {
  return axios.get('/api/v1/groups/categorys/', { params })
}

// 品牌列表
export const getBrands = params => {
  return axios.get('/api/operators/v1/brand/', { params })
}
// 获取抖音商品信息
export const getDouyinGoodsInfo = params => {
  return axios.get('/api/operators/v1/douyin-goods-info/', { params })
}

// 批量定时上下架
export const batchTimingOnSale = (data) => {
  return axios.post(`/api/operators/v1/batch-timing-on-sale/`, data)
}

// 商品搜索词统计列表
export const getSearchStat = (params) => {
    return axios.get(`/api/operators/v1/search-stat/`, {params})
}

// 获取商品预警列表
export const getWarningList = (params) => {
    return axios.get('/api/operators/v1/goods-warning/', { params })
}

// 解除商品预警
export const updateWarningStatus = (params) => {
    return axios.patch(`/api/operators/v1/goods-warning/`, params)
}

// 搜索提报活动
export const searchActiveList = (params) => {
  return axios.get(`/api/operators/v1/campaign/list/`, { params })
}

// 关联提报活动
export const relationActive = (params) => {
  return axios.post('/api/operators/v1/campaign/product/', params)
}

// 抓取商品历史
export const getGoodsSpider = (params) => {
    return axios.get('/api/operators/v1/goods/spider/', { params })
}

// 删除商品历史
export const deleteGoodsSpider = (uid) => {
    return axios.delete('/api/operators/v1/goods/spider/' + uid)
}


// 抓取商品
export const postGoodsSpider = (data) => {
    return axios.post('/api/operators/v1/goods/spider/', data)
}

// 商品分类
export const getNewGoodsCategorys = (params) => {
    return axios.get('/api/operators/v1/goods/category/', { params })
}

// 抓取商品
export const getGoodsSpiderDetail = (params) => {
    return axios.get(`/api/operators/v1/goods/spider/${params.uid}/goods/`, { params })
}

// 入库
export const postGoodsPutIn = (data) => {
    return axios.post('/api/operators/v1/goods/spider/goods/put-in/', data)
}

// 入库
export const postGoodsPutInAll = (uid) => {
    return axios.post(`/api/operators/v1/goods/spider/${uid}/put-in/`)
}
//服务费收入
export const getAllianceOrderList = (params) => {
  return axios.get('/api/operators/v1/alliance/orders/', { params })
}
//
// 服务费列表导出
export const exportAllianceOrderData = (params) => {
  return axios.get('/api/operators/v1/alliance/orders/export/', { params });
};
//招商、渠道汇总
export const getAllianceOrdersummaryList = (params) => {
  return axios.get('/api/operators/v1/alliance/orders/summary/', { params })
}
// 更新tag
export const editGoodsTagsIn = (data) => {
  return axios.put('/api/operators/v1/kuran-goods-tags/', data)
}
// 获取折一商品详情
export const getKrGoodsDetail = (params) => {
  return axios.get(`/api/operators/v1/kuran-goods/${params.id}/`)
}

<!--
 * @Description: 直播排期
 * @Autor: 胡椒
 * @Date: 2020-08-04 11:25:29
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-11 19:30:02
-->
<template>
  <div>
    <a-tabs type="card">
      <a-tab-pane
        v-for="(item, index) in options"
        :tab="item.label"
        :key="index"
      >
        <ServerIncomeList v-if="index===0"
          :ref="`list-${index}`"
        ></ServerIncomeList>
        <ServerIncomeChannelList :typenum="item.value" v-if="index!==0"
        ></ServerIncomeChannelList>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ServerIncomeList from '@/components/admin/statistical/server-income-list';
import ServerIncomeChannelList from '@/components/admin/statistical/server-income-channel-list';



export default {
  name: 'live',
  components: {
    ServerIncomeList,
    ServerIncomeChannelList,
  },

  data() {
    return {
      options:[{
        label: '服务费明细', value: 1
      }],
      activeKey: 0,

      // tabs商显示的排期数量 左→右依次为：
      // 全部/待主播申佣/待折一申佣/申佣待处理/待直播
      countArr: [0, 0, 0, 0, 0],

      operateList: [], // 操作人列表
    };
  },

  created() {
/*    if (this.$store.state.user.rights.includes("alliance_orders_manage_recommend_summary")) {
      this.options.push({  label: '按渠道汇总', value: 2})
    }*/
    if (this.$store.state.user.rights.includes("alliance_orders_manage_merchant_summary")) {
      this.options.push({  label: '按招商汇总', value: 3})
    }
  },

  methods: {
  },
};
</script>

<style lang="less" scoped>
.tool-bar {
  margin: 10px 0;
}
</style>

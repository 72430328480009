<!--
 * @Description: 直播排期
 * @Autor: 胡椒
 * @Date: 2020-08-04 11:25:29
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-13 10:01:35
-->
<template>
  <div id="sampleApply">
    <SearchBar style="margin-bottom: 10px"
      :searchParams="searchParams"
      :loading="loading"
       :exportLoading="exportLoading"
      @submit="search"
      @exportAllianceOrderClick="exportAllianceOrderClick"
    ></SearchBar>

    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'alliance_orders_manage'"
      :rowKey="(record) => record.order_id"
      :columns="columns"
      :dataSource="list"
      :pagination="pagination"
      :loading="loading"
      :locale="{ filterConfirm: '暂无数据' }"
      @change="handleTableChange"
    >
      <template slot="goods_news" slot-scope="record">
        <div class="container-item" style="width: 400px">
          <my-image v-if="record.product_img" :imgUrl="record.product_img"></my-image>
          <div style="width: 300px;display: inline-block;float: left;margin-left: 5px">
            <span style="height: 25px;width: 280px;display: inline-block;padding-left: 5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ record.product_name }}</span>
            <span style="height: 25px;width: 280px;padding-left: 5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">ID:{{ record.product_id }}</span>
          </div>
        </div>
     </template>
      <template slot="commission_service_rate" slot-scope="record">
        {{record.colonel_commission_rate ? record.colonel_commission_rate+'%' : ''}}
      </template>
      <template slot="order_status" slot-scope="record">
        {{record.order_status===1 ? '已支付' : record.order_status===2 ? '已退款' : record.order_status===3 ? '已结算' :''}}
      </template>
      <template slot="ordertime" slot-scope="record">
        <span style="text-align: left;height: 25px;display: inline-block;width: 100%">付款：{{String(record.pay_success_time||'-').replace('T',' ')}}</span>
        <span style="text-align: left;height: 25px;display: inline-block;width: 100%">结算：{{String(record.settle_time||'-').replace('T',' ')}}</span>
      </template>
    </a-table>


  </div>
</template>

<script>
import listMinxin from '@/mixin/listMixin';
import { getAllianceOrderList , } from "@/service/goods";
import SearchBar from '@/components/admin/statistical/server-list-search-bar';
import { baseURL } from '@/config/index';

// 商品列表
const columns = [
  {
    title: "订单ID",
    dataIndex: "order_id",
    fixed: 'left',
    width: 160,
  },
  {
    title: "活动ID",
    fixed: 'left',
    width: 100,
    dataIndex: "activity_id",
  },
  {
    title: "商品信息",
    scopedSlots: { customRender: "goods_news" },
    width: 300,
    align: "left",
  },
  {
    title: "订单状态",
    scopedSlots: { customRender: "order_status" },
    width: 200,
    align: "center",
  },
  {
    title: "达人昵称",
    align: "center",
    dataIndex: "author_account",
    width: 200,
  },
  {
    title: "支付金额",
    align: "center",
    dataIndex: "total_pay_amount",
    width: 150,
  },
  {
    title: "服务费率",
    align: "center",
    scopedSlots: { customRender: "commission_service_rate" },
    width: 100,
  },
  {
    title: "预估服务费",
    align: "center",
    dataIndex: "colonel_estimated_commission",
    width: 150,
  },
  {
    title: "实际服务费",
    align: "center",
    dataIndex: "colonel_real_commission",
    width: 150,
  },
  {
    title: "渠道",
    align: "center",
    dataIndex: "recommend_user_name",
    width: 150,
  },
  {
    title: "招商",
    dataIndex: "merchant_user_name",
    width: 150,
    align: "center",
  },
  {
    title: "订单时间",
    scopedSlots: { customRender: "ordertime" },
    align: "center",
    width: 260,
  },
];

export default {
  mixins: [listMinxin],
  components: {
    SearchBar,
  },

  data() {
    return {
      baseURL,
      exportLoading:false,
      loading:false,
      tableScrollY: 500,
      selectedRowKeys: [],
      currentRecord: {},
      columns: columns,
      isModalCancelVisible: false, // 取消弹框
      isModalUpdateStatusVisible: false, // 更新状态弹框
      isModalExportVisible: false, // 导出直播资料弹框
      isScheduleDetailVisible: false, // 排期详情弹框
      // 检索参数
      searchParams: {
        order_status: undefined, // 订单状态
        order_id: undefined,
        product_id:undefined,
        search_user_type: 1,
        search_user_value: undefined,
        pay_success_time_start: undefined, // 开始时间, 配合time_type
        pay_success_time_end: undefined, // 开始时间, 配合time_type
        settle_time_start: undefined, // 开始时间, 配合time_type
        settle_time_end: undefined, // 开始时间, 配合time_type
      },
      pagination: {
        size: 'small',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },

  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 395;
    this.getList();
  },

  methods: {
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList();
    },
    // 获取数据列表
    async getList() {
      this.loading = true;
      const { res } = await getAllianceOrderList({
        ...this.searchParams,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.loading = false;
      if (res && res.success) {
        this.list = res.data.results;
        this.pagination = Object.assign(
          { ...this.pagination },
          { total: res.data.count }
        );
      }
    },

    // 用户点击查询
    search(values) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, this.searchParams, values);
      if(this.searchParams.order_id){
        this.searchParams.order_id=String(this.searchParams.order_id)
      }
      if(this.searchParams.product_id){
        this.searchParams.product_id=String(this.searchParams.product_id)
      }
      this.getList();
    },
    // 导出列表处理
    async exportAllianceOrderClick() {
      // this.searchParams = Object.assign({}, this.searchParams, values);
      if(this.pagination.total>10000) {
        this.$message.error("数据明细过多(限1万条)，请减少导出数据明细");
        // this.$message.warning("数据明细过多(限1万条)，请减少导出数据明细");
        return ;
      }
      const token = this.$store.getters.getToken;
      const  Authorization=token ? token : undefined;
      var url=this.baseURL+'/api/operators/v1/alliance/orders/export/?Authorization='+Authorization;
      url = this.Add(url,this.searchParams.order_status,'order_status');
      url = this.Add(url,this.searchParams.order_id,'order_id');
      url = this.Add(url,this.searchParams.product_id,'product_id');
      url = this.Add(url,this.searchParams.pay_success_time_start,'pay_success_time_start');
      url = this.Add(url,this.searchParams.pay_success_time_end,'pay_success_time_end');
      url = this.Add(url,this.searchParams.settle_time_start,'settle_time_start');
      url = this.Add(url,this.searchParams.settle_time_end,'settle_time_end');
      url = this.Add(url,this.searchParams.search_user_type,'search_user_type');
      if(this.searchParams.search_user_type){
        url = this.Add(url,this.searchParams.search_user_value,'search_user_value');
      }
      window.open(url);
    /*  this.exportLoading=true;
      this.searchParams = Object.assign({}, this.searchParams, values);
      const { res } = await exportAllianceOrderData({
        ...this.searchParams,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.exportLoading=false;
      if (res && res.success) {
        location.href = res.data.detail_url;
        this.$message.success('导出成功');
      }*/
    },
  /*  isfirst(str){
      var arr=String(str).split('');
      var laststr=arr[arr.length-1];
      if(laststr==="?"){
        return true;
      }
      return false;
    },*/
    Add(url,str,key){
       if(str){
           url=url+'&'+key+'='+str
       }
       return url;
    },
  },
};
</script>

<style lang="less" scoped>
  #sampleApply{
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }
.operation-btn {
  margin: 2px;
}
  .el-img{
    width: 54px;
    height: 54px;
    /deep/ .el-image__inner {
      width: 54px;
      height: 54px;
    }
    .el-image__inner {
      width: 54px;
      height: 54px;
    }
  }
</style>

<!--
 * @Description: 直播排期
 * @Autor: 胡椒
 * @Date: 2020-08-04 11:25:29
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-13 10:01:35
-->
<template>
  <div id="sampleApply">
    <div style="margin-bottom: 10px">
      <a-form
        class="filter-condition-form"
        layout="inline"
      >
        <a-form-item>
          <a-select
            v-model='selectyear'
            style="width: 120px;"
            placeholder=""
          >
            <a-select-option
              v-for="item in expressYearOptions"
              :key="item.id"
            >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-select
            v-model='selectmonth'
            style="width: 120px;margin-left: 10px"
            placeholder=""
          >
            <a-select-option
              v-for="item in expressStatusOptions"
              :key="item.id"
            >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            :loading="loading"
            @click="getList"
          >查询</a-button
          ></a-form-item>
        <a-form-item><div style="margin-left: 10px">
          <a-icon type="exclamation-circle"/> 当前数据只统计在所选月份结算和退款的订单
        </div></a-form-item>
      </a-form>
    </div>
    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="typenum===2?'alliance_orders_manage_recommend_summary':'alliance_orders_manage_merchant_summary'"
      :rowKey="(record) => (record.merchant_user_id|| record.recommend_user_id)"
      :columns="typenum===2?columns:columns2"
      :dataSource="list"
      :pagination="pagination"
      :loading="loading"
      :locale="{ filterConfirm: '暂无数据' }"
      @change="handleTableChange"
    >
      <template slot="commission_service_rate" slot-scope="record">
        {{record.colonel_commission_rate ? record.colonel_commission_rate+'%' : ''}}
      </template>
      <template slot="operation" slot-scope="record">
        <a-button v-if="iscanExport" type="primary" size="small" class="mr-10" @click="exportAllianceOrderClick(record)" >导出明细</a-button>
        <a-button v-else disabled size="small" class="mr-10">导出明细</a-button>
      </template>
    </a-table>
  </div>
</template>

<script>
import listMinxin from '@/mixin/listMixin';
//exportAllianceOrderData
import { getAllianceOrdersummaryList ,  } from "@/service/goods";
import { baseURL } from '@/config/index';
// 商品列表
const columns = [
  {
    title: "渠道人员",
    dataIndex: "recommend_user_name",
    fixed: 'left',
    width: 120,
  },
  {
    title: "订单数",
    fixed: 'left',
    width: 100,
    dataIndex: "order_count",
  },
  {
    title: "支付金额",
    dataIndex: "total_pay_amount",
    width: 150,
    align: "left",
  },
  {
    title: "预估服务费",
    dataIndex: "colonel_estimated_commission",
    width: 150,
    align: "left",
  },
  {
    title: "实际服务费",
    dataIndex: "colonel_real_commission",
    width: 110,
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
    width: 150,
    fixed: 'right',
  },
];
const columns2 = [
  {
    title: "招商人员",
    dataIndex: "merchant_user_name",
    fixed: 'left',
    width: 120,
  },
  {
    title: "订单数",
    fixed: 'left',
    width: 100,
    dataIndex: "order_count",
  },
  {
    title: "支付金额",
    dataIndex: "total_pay_amount",
    width: 150,
    align: "left",
  },
  {
    title: "预估服务费",
    dataIndex: "colonel_estimated_commission",
    width: 150,
    align: "left",
  },
  {
    title: "实际服务费",
    dataIndex: "colonel_real_commission",
    width: 110,
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
    width: 150,
    fixed: 'right',
  },
];
export default {
  mixins: [listMinxin],
  components: {
  },
  props: {
    typenum: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      baseURL,
      loading:false,
      tableScrollY: 500,
      selectedRowKeys: [],
      currentRecord: {},
      columns: columns,
      columns2: columns2,
      selectyear:undefined,
      selectmonth: undefined,
      expressStatusOptions: [
        {label:'1月', id: 1},
        {label:'2月', id: 2},
        {label:'3月', id: 3},
        {label:'4月', id: 4},
        {label:'5月', id: 5},
        {label:'6月', id: 6},
        {label:'7月', id: 7},
        {label:'8月', id: 8},
        {label:'9月', id: 9},
        {label:'10月', id: 10},
        {label:'11月', id: 11},
        {label:'12月', id: 12},
      ],
      expressYearOptions: [
        {label: '2021年', id: 2021},
        {label: '2022年', id: 2022},
      ],
      pagination: {
        size: 'small',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
      iscanExport: false

    };
  },

  created() {
    if (this.typenum===2&&this.$store.state.user.rights.includes("alliance_orders_manage_recommend_summary_export")) {
      this.iscanExport=true;
    }
    if (this.typenum===3&&this.$store.state.user.rights.includes("alliance_orders_manage_merchant_summary_export")) {
      this.iscanExport=true;
    }
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 385;
    var today = new Date();
    var month=today.getMonth();
    var year=today.getFullYear();
    this.selectmonth=month+1;
    this.selectyear=year;
    this.getList();
  },

  methods: {
    // 获取数据列表
    async getList() {
      if ((this.typenum===2&&this.$store.state.user.rights.includes("alliance_orders_manage_recommend_summary")) || (this.typenum===3&&this.$store.state.user.rights.includes("alliance_orders_manage_merchant_summary"))){
        this.loading = true;
        const {res} = await getAllianceOrdersummaryList({
          date_month_str: String(this.selectyear)+'-'+String(this.selectmonth),
          page: this.pagination.current,
          page_size: this.pagination.pageSize,
          summary_type: this.typenum === 2 ? 'recommend_user' : 'merchant_user',
        });
        this.loading = false;
        if (res && res.success) {
          this.list = res.data.results;
          this.pagination = Object.assign(
            {...this.pagination},
            {total: res.data.count}
          );
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList();
    },
    // 导出列表处理
    async exportAllianceOrderClick(record) {
      const token = this.$store.getters.getToken;
      const  Authorization=token ? token : undefined;
      var url=this.baseURL+'/api/operators/v1/alliance/orders/export/?Authorization='+Authorization+'&date_month_str='+String(this.selectyear)+'-'+String(this.selectmonth);
      if(this.typenum===2){
        url=url+'&recommend_user_id='+record.recommend_user_id;
      }
      if(this.typenum===3){
        url=url+'&merchant_user_id='+record.merchant_user_id;
      }
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
  #sampleApply{
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }
.operation-btn {
  margin: 2px;
}
  .el-img{
    width: 54px;
    height: 54px;
    /deep/ .el-image__inner {
      width: 54px;
      height: 54px;
    }
    .el-image__inner {
      width: 54px;
      height: 54px;
    }
  }
  .icon {
    font-family: iconfont;
  }
</style>

/*
 * @Description:
 * @Autor: 胡椒
 * @Date: 2020-08-04 18:55:03
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-08 17:24:20
 */
export default {
  data() {
    return {
      loading: false,
      list: [],

      // 分页
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },

  methods: {
    // 分页
    handleTableChange(pagination) {
      if (pagination) {
        this.pagination.current = pagination.current;
      }
      this.getList();
    },
  },
};
